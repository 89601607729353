$debug: false;

$font-path: '../font';
$img-path: '../images';

$viewport-min-width: 320px;

$block-gap: var(---block-gap);

$box-radius-size: 6px;
$box-padding-vert: 5vh;

$transition-duration: 150ms;

$site-header-tall-height: 60px;
$site-header-short-height: 40px;
$site-header-sticky-max-width: 1780px;
$site-header-height: var(---site-header-height);
$site-header-margin: var(---site-header-margin);
$site-header-margin-extra: 20px;
$site-header-outer-height: calc($site-header-height + $site-header-margin + var(---sticky-gap));

$main-margin: var(---main-margin);
$main-max-width: 1300px !default;

$topnav-visible: 1020px; // where topnav menu & WWWWWWWWWWWWWWWWWWWW both fit at 100%

$xxx-small: 400px;
$xx-small: 500px;
$x-small: 650px;
$small: 800px;
$medium: 980px;
$large: 1120px;
$x-large: 1260px;
$xx-large: 1500px;

$x-short: 400px;
$short: 500px;
$tall: 600px;
$x-tall: 700px;
